/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/index.css';
@import '~quill/dist/quill.bubble.css'; 
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~ngx-toastr/toastr.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: .75rem 1.25rem .75rem 50px !important;
    width: 500px !important;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
  }
  .pt-2{
    padding-top: 2px;
  }
  .fw-bolder{
    font-weight: bolder;
  }
  
  .starsize{
    font-size:12px;
  }
  .custom-error-css {
    font-size: 14px;
    color: red;
 }
 .modal-header {
  background: #299198;
  color: white !important;
  padding: 0.75rem 1.75rem !important;
}

.modal-title{
  color:white !important;
}

.modal-footer {
  padding: 1rem !important;
}
.ir-2{
  margin-right: -16px !important;
}
.btn-yellow{
  background-color: #FFC72C;
  color:#007c85;
  border-color: #FFC72C;
  font-weight: bold;
}
.btn-yellow:hover {
  color: #007c85;
  font-weight: bold;
}
.text-bold{
  font-weight: bold !important;
}